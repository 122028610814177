<template>
  <webTemplate v-if="isMounted">
  </webTemplate>
</template>

<script>
const webType = process.env.VUE_APP_WEBSITE_MASTER;
let webTemplate = null;
if(webType === "wmy"){
  webTemplate = () => import('./views/wmy/WmyIndex');
}else if(webType === "metaverse"){
  webTemplate = () => import('./views/metaverse/MetaverseIndex');
}else {
  webTemplate = () => import('./views/OfficialWebsite');
}
import { mapState } from "vuex";
console.log("domainjij",process.env.VUE_APP_NODE_ENV);
console.log("domainjij--yuan",process.env.NODE_ENV);
export default {
  name: 'App',
  components: {webTemplate},
  data () {
    return {
      timeId:"",
      isMounted: true,//避免初始路由切换的页面闪动
    }
  },
  computed: {
    ...mapState(["officialWebsite"]),
  },
  mounted() {
    window.onscroll = this.justifyPos;
    window.onresize = this.handleResize;
    this.isMounted = window.location.pathname === "/";
    this.handleResize();
  },
  methods: {
    justifyPos() {
      this.$route.meta.y = window.pageYOffset;
      this.$store.commit("officialWebsite/setShowH5SusBtn", false);
      this.$store.commit("officialWebsite/setShowReturnTip", this.$route.meta.y);
      if(this.timeId) clearTimeout(this.timeId);
      this.timeId = setTimeout(()=>{
        this.$store.commit("officialWebsite/setShowH5SusBtn", true);
      },2000);
      // 根据滚动做各种细节处理
      // if(this.timeId) clearTimeout(this.timeId);
      // this.timeId = setTimeout(()=>{
      //   this.$route.meta.y = window.pageYOffset;
      //   this.$store.commit("officialWebsite/setShowReturnTip", this.$route.meta.y > 0)
      // },50);
    },
    handleResize() {
      const clientWidth = document.body.clientWidth;
      const pathType = window.location.pathname.split("/")[1];
      let isH5 = pathType === "h5" ? true : pathType === "wmyH5";
      const marginLeft = -30+(clientWidth-window.outerWidth)*0.68+'px';
      const marginTop = -150+((window.outerHeight-window.screen.availHeight)*0.71)+'px';
      this.$store.commit("officialWebsite/setMarginLeft", marginLeft);
      this.$store.commit("officialWebsite/setMarginTop", marginTop);
      this.$store.commit("officialWebsite/setClientWidth", clientWidth);
      this.$store.commit("officialWebsite/setWindowResize");
      let openUrl = window.location.protocol + "//" + window.location.host + this.decideType(isH5);
      if(clientWidth > 1024){
        if(isH5){ //从移动端切为pc端
          window.location.href = openUrl;
        }
      }else {
        if(!isH5){ //从pc端切换为移动端
          window.location.href = openUrl;
        }
      }
      if(!this.isMounted){
        setTimeout(()=>{
          this.isMounted = true;
        },500)
      }
    },
    //判断属于哪一个主站
    decideType(isH5) {//customize,metaverse,
      const routeType = process.env.VUE_APP_WEBSITE_MASTER;
      if(routeType === "metaverse"){
        if(window.location.pathname.indexOf("develop") === -1){
          return isH5 ? "/":"/h5/Home";//元宇宙官网
        }else {
          return isH5 ? "/develop/Home":"/h5/develop/Home";//元宇宙官网
        }
      }else if(routeType === "wmy"){
        return isH5 ? "/":"/wmyH5/wmyH5Home";//万目云官网
      }else {
        return isH5 ? "/":"/h5/Home";//掌中飞天官网
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
