import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import "animate.css"
import wow from 'wowjs'
import "../public/static/font.css"
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './sass/main.scss';//手动引入颜色包，减少最终打包大小
import './sass/reset.scss';//pc覆盖样式表
import './sass/swiper.scss';//swiper
import './sass/h5Reset.scss';//h5覆盖样式表
import './sass/animateReset.scss';//覆盖动画
import './styles/styles.scss';//覆盖全局属性
import VueAMap from "vue-amap";
import Cookies from "js-cookie";


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
    key: 'd9c152ce9c12d71e334a497608e8d1ca\t',
    plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
    v: '1.4.4',
    uiVersion: '1.0'
})


import _ from "lodash";
Vue.prototype.$_ = _;
Vue.prototype.$Cookies = Cookies;

import ImgPlaceholder from '@/components/utils/ImgPlaceholder.vue'; // 图片加载placeholder
import CustomizeHeader from '@/views/CustomizeHeader.vue'; // 子站落地页导航
import CustomizeFooter from '@/views/CustomizeFooter.vue'; // 子站落地页页脚
import CustomizeSuspendBtn from '@/views/CustomizeSuspendBtn.vue'; // 子站落地页悬浮按钮
Vue.component("img-placeholder",ImgPlaceholder);
Vue.component("customize-header",CustomizeHeader);
Vue.component("customize-footer",CustomizeFooter);
Vue.component("customize-suspendBtn",CustomizeSuspendBtn);
Vue.use(CustomizeHeader);
import lavMessage from './components/utils/lavMessage/lavMessage' // 全局提示
Vue.config.productionTip = false
Vue.prototype.$lavMessage = lavMessage
Vue.prototype.$wow = wow
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
